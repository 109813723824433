import styled from "styled-components";
import { Navbar as ReactNavbar, Nav, Container, Button } from "react-bootstrap";
import { COLORS } from "../../assets";

export const StyledNavbar = styled(ReactNavbar)`
  background-color: white !important;
`;

export const Link = styled(Nav.Link)`
  color: ${COLORS.primary} !important;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  margin-left: 3rem;
  font-weight: 500;
`;

export const Store = styled.div`
  display: flex;
  flex: 1;
  background-color: ${COLORS.primary};
  color: ${COLORS.white} !important;
  border-radius: 5px;
  padding: 0.3rem 1rem;
  margin-left: 3rem;
`;

export const StoreLink = styled(Link)`
  margin-left: 0;
  color: ${COLORS.white} !important;
  font-weight: 400;
`;

export const Icon = styled.img`
  margin-left: 0.5rem;
`;

export const Toggle = styled(ReactNavbar.Toggle)`
  background-color: ${COLORS.primary};

  :focus {
    outline: 0;
  }

  :active {
    outline: 0;
  }
`;
