import React from "react";
import * as Styles from "./styles";

function SmallFooter({ textAlign, color }) {
  return (
    <Styles.Container textAlign={textAlign}>
      <Styles.Link
        href="https://www.instagram.com/petvittaaracaju/"
        color={color}
        alt="Instagram PetVitta Aracaju"
      >
        @petvittaaracaju
      </Styles.Link>
      <Styles.Address color={color}>
        Rua Antonio Freire Piuga, 585 - Aruana Aracaju, Brazil
      </Styles.Address>
    </Styles.Container>
  );
}

export default SmallFooter;
