import bone from "../images/svgs/bone.svg";
import logoWithCircle from "../images/svgs/logo-with-circle.svg";
import horizontalLogo from "../images/svgs/horizontal-logo.svg";
import verticalLogo from "../images/svgs/vertical-logo.svg";
import paws from "../images/svgs/paws.svg";
import petHouse from "../images/svgs/pet-house.svg";
import petShower from "../images/svgs/pet-shower.svg";
import titleDetail from "../images/svgs/title-detail.svg";
import veterinarian from "../images/svgs/veterinarian.svg";
import veterinaryClinic from "../images/svgs/veterinary-clinic.svg";
import cart from "../images/svgs/cart.svg";
import instagram from "../images/svgs/instagram.svg";
import whatsapp from "../images/svgs/whatsapp.svg";
import pin from "../images/svgs/pin.svg";
import whatsappBlack from "../images/svgs/whatsapp_black.svg";
import pinBlack from "../images/svgs/pin_black.svg";
import callPhone from "../images/svgs/call-phone.svg";
import website from "../images/svgs/website.svg";
import shoppingCart from "../images/svgs/shopping-cart.svg";
import leafsBG from "../images/svgs/leafs_bg.svg";
import leafsBGMobile from "../images/svgs/leafs_bg_mobile.svg";
import notes from "../images/svgs/notes.svg";

export default {
  bone,
  horizontalLogo,
  logoWithCircle,
  paws,
  petHouse,
  petShower,
  titleDetail,
  veterinarian,
  veterinaryClinic,
  cart,
  whatsapp,
  instagram,
  callPhone,
  pin,
  website,
  shoppingCart,
  leafsBG,
  verticalLogo,
  whatsappBlack,
  pinBlack,
  leafsBGMobile,
  notes,
};
