import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { SVGS, COLORS } from "../../assets";
import * as Styles from "./styles.js";

const NavbarComponent = () => {
  return (
    <Styles.StyledNavbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={SVGS.horizontalLogo}
            height="90"
            className="d-inline-block align-top"
            alt="PetVitta Aracaju"
          />
        </Navbar.Brand>
        <Styles.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav className="align-items-center">
            <Styles.Link href="#about">Quem Somos</Styles.Link>
            <Styles.Link href="#services">Nossos Serviços</Styles.Link>
            <Styles.Link href="#link">Blog</Styles.Link>
            <Styles.Store>
              <Styles.StoreLink href="#home">Loja Online</Styles.StoreLink>
              <Styles.Icon
                src={SVGS.cart}
                width="24"
                className="d-inline-block align-top"
                alt="PetVitta Aracaju"
              />
            </Styles.Store>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Styles.StyledNavbar>
  );
};

export default NavbarComponent;
