import styled from "styled-components";
import {
  Navbar as ReactNavbar,
  Nav,
  Container as ReactContainer,
  Row,
} from "react-bootstrap";
import { COLORS, SVGS } from "../assets";

export const Title = styled.h2`
  color: ${COLORS.primary} !important;
  text-transform: uppercase;
  font-weight: 900;
  max-width: 300px;

  :after {
    content: url(${SVGS.titleDetail});
    display: block;
  }
`;

export const Button = styled.button`
  background-color: ${COLORS.primary};
  color: ${COLORS.white};
  border: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.875rem;
  padding: 1rem 1.875rem;
  outline: 0;
  border-radius: 5px;

  :hover {
    background-color: ${COLORS.primary};
  }

  :focus {
    outline: 0;
  }

  :active {
    outline: 0;
  }
`;
