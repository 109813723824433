import React from "react";
import { Helmet } from "react-helmet";

function SEO({ textAlign, color }) {
  return (
    <Helmet>
      <title>PetVitta Aracaju – Centro de bem-estar animal</title>
      <meta
        name="description"
        content="O PetVitta Aracaju é um centro de bem-estar animal. Nossas instalações contam com clinica veterinária, creche e hotel para cães, banho e tosa, e petshop."
      ></meta>
    </Helmet>
  );
}

export default SEO;
