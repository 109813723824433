import styled from "styled-components";
import { COLORS } from "../../assets";

export const Container = styled.div`
  text-align: ${(props) => props.textAlign};
  margin-top: 50px;
`;

export const Link = styled.a`
  color: ${(props) => props.color || COLORS.primary} !important;
  font-weight: 700;
  letter-spacing: 0.05rem;
`;

export const Address = styled.p`
  color: ${(props) => props.color || COLORS.primary} !important;
`;
